/* eslint-disable camelcase */
import { chakra, Flex, Text, BoxProps } from "@chakra-ui/react";
import { Carousel } from "components/Carousel";
import React from "react";
import { GetHomePageContent_homePage } from "types/GetHomePageContent";
import Scroll from "react-scroll";

interface CtaProps extends BoxProps {
  testimonials: GetHomePageContent_homePage["Testimonials"];
}

const Testimonials: React.FC<CtaProps> = ({ testimonials, ...rest }) => {
  const Element = Scroll.Element;

  return (
    <chakra.section
      minH={{ base: "552px", md: "514px", lg: "545px" }}
      bg="primary.darkgreen"
      {...rest}
    >
      <Element name="Section6">
        <Carousel>
          {testimonials?.map((message) => {
            return (
              <Flex
                minH="calc(545px - 60px)"
                color="white"
                key={message?.id}
                flexDirection="column"
                textAlign="center"
                alignItems="center"
              >
                <Text
                  fontSize={{ base: "150px", md: "200px" }}
                  lineHeight={{ base: "100px", md: "50px" }}
                  pt={{ base: "80px", md: "120px" }}
                  opacity="0.4"
                >
                  “
                </Text>
                <Text
                  fontSize={{ base: "18px", md: "24px", lg: "32px" }}
                  lineHeight={{ base: "27px", md: "36px", lg: "45px" }}
                  maxW={{ base: "310px", md: "639px", lg: "855px" }}
                  mb={{ base: "30px", md: "48px", lg: "16px" }}
                >
                  “{message?.Message}”
                </Text>
                <Text
                  fontSize={{ base: "18px", md: "24px" }}
                  lineHeight={{ base: "27px", md: "32px" }}
                  mb="8px"
                >
                  {message?.Name}
                </Text>
                <Text
                  fontSize={{ base: "14px", md: "18px" }}
                  lineHeight={{ base: "21px", md: "27px" }}
                  opacity="0.6"
                >
                  {message?.Position}, {message?.Company}
                </Text>
              </Flex>
            );
          })}
        </Carousel>
      </Element>
    </chakra.section>
  );
};

export default Testimonials;
