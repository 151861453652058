import { Button, ButtonProps } from "@chakra-ui/react";

interface GettingStartedLinkProps {
  href: string;
}

const GettingStartedLink = ({
  href,
  ...props
}: GettingStartedLinkProps & Omit<ButtonProps, "children">) => {
  return (
    <Button
      id="1"
      as="a"
      href={href}
      rel="noreferrer"
      target="_blank"
      _hover={{ opacity: 1 }}
      _loading={{ opacity: 0.6 }}
      {...props}
    >
      Get Started
    </Button>
  );
};

export default GettingStartedLink;
