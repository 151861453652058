/* eslint-disable camelcase */
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import GettingStartedLink from "@components/GettingStartedLink";
import { WithAppConfigs } from "@utils/appConfigs";
import Link from "next/link";
import React from "react";
import { HiChevronRight } from "react-icons/hi";
import { GetHomePageContent } from "types/GetHomePageContent";

interface HomePageProps {
  homeContent: GetHomePageContent["homePage"];
}

const Hero: React.FC<WithAppConfigs<HomePageProps>> = ({
  homeContent,
  configs,
}) => {
  const btnSize = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });

  return (
    <Container
      px={{ base: "24px", lg: "0px" }}
      maxW={{
        base: "container.mobile",
        md: "container.tablet",
        lg: "container.desktop",
      }}
    >
      <Flex
        pt={{ base: "46px", md: "70px", lg: "89px" }}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Center flex="1" px={{ base: "0px", md: "0px" }}>
          <Flex
            alignSelf="flex-start"
            flexDirection="column"
            textAlign={{ base: "center", md: "unset" }}
          >
            <Heading
              variant={useBreakpointValue({ base: "h4", lg: "h2" })}
              maxW={{ base: "304px", lg: "570px" }}
              mb="24px"
              alignSelf={{ base: "center", md: "unset" }}
            >
              {homeContent?.Section1Headline}
            </Heading>
            {homeContent?.Section1Body?.map((bodyData) => {
              return (
                <Text
                  key={bodyData?.id}
                  mb="16px"
                  maxW="543px"
                  fontSize={{ base: "16px", lg: "21px" }}
                  lineHeight={{ base: "27px", lg: "32px" }}
                  fontWeight="500"
                >
                  {bodyData?.Content}
                </Text>
              );
            })}
            <Flex flexDirection={{ base: "column", md: "row" }}>
              <GettingStartedLink
                mt="8px"
                href={configs.gettingStartedUrl}
                size={btnSize}
                px={{ base: 4 }}
                w={{ base: "fit-content", lg: "unset" }}
                mr={{ base: "auto", md: "48px" }}
                ml={{ base: "auto", md: "0px" }}
                mb={{ base: "24px", md: "0px" }}
              />

              <Link passHref href="/about">
                <Center
                  mb={{ base: "56px", md: "0px" }}
                  _hover={{ cursor: "pointer" }}
                >
                  <Text variant="learn-more-cta">Learn More</Text>
                  <Icon
                    as={HiChevronRight}
                    height="20px"
                    width="20px"
                    ml="12px"
                    color="primary.darkgreen"
                  />
                </Center>
              </Link>
            </Flex>
          </Flex>
        </Center>
        <Center
          position="relative"
          overflow="hidden"
          height={{ base: "427px", md: "516px", lg: "633.6px" }}
        >
          <Box
            bgImage={"url(/images/pattern.png)"}
            bgRepeat="no-repeat"
            w={{ base: "296px", md: "328.58px", lg: "380px" }}
            h={{ base: "398px", md: "432.32px", lg: "533px" }}
            position="absolute"
            transform={{
              base: "translate(10%, 10%)",
              md: "translate(8.5%, 7%)",
              lg: "translate(8%, 10.5%)",
            }}
            zIndex="-1"
          />
          <Image
            alignSelf="flex-start"
            w={{ base: "290.42px", md: "322.09px", lg: "397px" }}
            h={{ base: "397.96px", md: "483.55px", lg: "596px" }}
            src="/images/img-main.png"
            alt="Hero Featured Photo"
            borderRadius="lg"
            mr={{ base: "0px", md: "24px", lg: "48px" }}
          />
        </Center>
      </Flex>
    </Container>
  );
};

export default Hero;
