import {
  Box,
  Center,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineScale,
  HiOutlineShieldCheck,
  HiOutlineSparkles,
  HiOutlineSpeakerphone,
} from "react-icons/hi";

const HowItWorks = () => {
  const DATA = [
    {
      imgSrc: "/images/grow-with-us/pen.svg",
      icon: <Icon as={HiOutlineSpeakerphone} height="58px" width="58px" />,
      altName: "Pen logo",
      title: "Tell us the marketing function you need to hire",
    },
    {
      imgSrc: "/images/grow-with-us/plane.svg",
      icon: <Icon as={HiOutlineScale} height="58px" width="58px" />,
      altName: "Plane logo",
      title: "We source and screen the right candidates",
    },
    {
      imgSrc: "/images/grow-with-us/people.svg",
      icon: <Icon as={HiOutlineSparkles} height="58px" width="58px" />,
      altName: "People logo",
      title: "Our Deputy is placed within your business",
    },
    {
      imgSrc: "/images/grow-with-us/trophy.svg",
      icon: <Icon as={HiOutlineShieldCheck} height="58px" width="58px" />,
      altName: "Trophy logo",
      title:
        "We provide ongoing support to every Deputy and our feedback loop aims for continuous improvement",
    },
  ];

  return (
    <Container
      px="0"
      maxW={{
        base: "container.mobile",
        md: "container.tablet",
        lg: "container.desktop",
      }}
      mt={{ base: "56px", md: "92px", lg: "87px" }}
      mb={{ base: "25px", md: "58px", lg: "45px" }}
    >
      <Center mb="54px">
        <Heading variant={useBreakpointValue({ base: "h4", lg: "h3" })}>
          How It Works
        </Heading>
      </Center>
      <Center px="24px">
        <SimpleGrid spacing="48px" columns={{ base: 2, md: 4 }}>
          {DATA.map((item) => {
            return (
              <Box
                key={item.altName}
                maxW={{ base: "160px", md: "128px", lg: "245px" }}
              >
                <Center maxH="43px" height="auto" mb="35px">
                  {item.icon}
                </Center>
                <Center>
                  <Text
                    maxW={{ base: "160px", lg: "245px" }}
                    fontSize={{ base: "16px", lg: "18px" }}
                    lineHeight={{ base: "24px", lg: "22.77px" }}
                    textAlign="center"
                  >
                    {item.title}
                  </Text>
                </Center>
              </Box>
            );
          })}
        </SimpleGrid>
      </Center>
    </Container>
  );
};

export default HowItWorks;
