import { createIcon } from "@chakra-ui/icons";

// using `path`
export const CurlyCheckIcon = createIcon({
  displayName: "CurlyCheckIcon",
  viewBox: "0 0 16 16",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.26698 1.45486C4.91024 1.4035 5.52089 1.15049 6.01198 0.73186C6.56664 0.259451 7.27141 0 7.99998 0C8.72856 0 9.43332 0.259451 9.98798 0.73186C10.4791 1.15049 11.0897 1.4035 11.733 1.45486C12.4594 1.51292 13.1414 1.82782 13.6567 2.34313C14.172 2.85843 14.4869 3.54043 14.545 4.26686C14.596 4.90986 14.849 5.52086 15.268 6.01186C15.7404 6.56652 15.9998 7.27129 15.9998 7.99986C15.9998 8.72843 15.7404 9.4332 15.268 9.98786C14.8493 10.4789 14.5963 11.0896 14.545 11.7329C14.4869 12.4593 14.172 13.1413 13.6567 13.6566C13.1414 14.1719 12.4594 14.4868 11.733 14.5449C11.0897 14.5962 10.4791 14.8492 9.98798 15.2679C9.43332 15.7403 8.72856 15.9997 7.99998 15.9997C7.27141 15.9997 6.56664 15.7403 6.01198 15.2679C5.52089 14.8492 4.91024 14.5962 4.26698 14.5449C3.54055 14.4868 2.85855 14.1719 2.34325 13.6566C1.82794 13.1413 1.51304 12.4593 1.45498 11.7329C1.40362 11.0896 1.15061 10.4789 0.731982 9.98786C0.259573 9.4332 0.00012207 8.72843 0.00012207 7.99986C0.00012207 7.27129 0.259573 6.56652 0.731982 6.01186C1.15061 5.52077 1.40362 4.91012 1.45498 4.26686C1.51304 3.54043 1.82794 2.85843 2.34325 2.34313C2.85855 1.82782 3.54055 1.51292 4.26698 1.45486ZM11.707 6.70686C11.8891 6.51826 11.9899 6.26566 11.9877 6.00346C11.9854 5.74126 11.8802 5.49045 11.6948 5.30504C11.5094 5.11963 11.2586 5.01446 10.9964 5.01219C10.7342 5.00991 10.4816 5.1107 10.293 5.29286L6.99998 8.58586L5.70698 7.29286C5.51838 7.1107 5.26578 7.00991 5.00358 7.01219C4.74138 7.01446 4.49057 7.11963 4.30516 7.30504C4.11976 7.49045 4.01459 7.74126 4.01231 8.00346C4.01003 8.26566 4.11082 8.51826 4.29298 8.70686L6.29298 10.7069C6.48051 10.8943 6.73482 10.9996 6.99998 10.9996C7.26515 10.9996 7.51945 10.8943 7.70698 10.7069L11.707 6.70686Z"
      fill="#2D7339"
    />
  ),
});
