/* eslint-disable camelcase */
import {
  Box,
  Center,
  chakra,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import GettingStartedLink from "@components/GettingStartedLink";
import { WithAppConfigs } from "@utils/appConfigs";
import { CurlyCheckIcon } from "icons/CurlyCheckIcon";
import React from "react";
import { GetHomePageContent } from "types/GetHomePageContent";

interface CtaProps {
  homeContent: GetHomePageContent["homePage"];
}

const Cta: React.FC<WithAppConfigs<CtaProps>> = ({ homeContent, configs }) => {
  const btnSize = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });

  return (
    <chakra.section h="auto">
      <Container
        px={{ base: "0", md: "24px", lg: "0px" }}
        maxW={{
          base: "container.mobile",
          md: "container.tablet",
          lg: "container.desktop",
        }}
        pt={{ base: "24px", md: "54px", lg: "100px" }}
      >
        <Center
          textAlign="center"
          display={useBreakpointValue({
            base: "none",
            md: "flex",
            lg: "none",
          })}
        >
          <Heading variant="h4" maxW="560px" mx="104px" mb="32px">
            We Understand Hiring for Marketing Is a Real Struggle
          </Heading>
        </Center>
        <Flex
          minH="auto"
          flexDirection={{ base: "column", md: "row" }}
          pb={{ base: "39px", md: "79px", lg: "100px" }}
        >
          <Box
            flex="1"
            px={{ base: "24px", md: "0px" }}
            my={{ base: "unset", lg: "auto" }}
          >
            <Center
              width={{ base: "contain", lg: "397px" }}
              ml="auto"
              mr={{ base: "0px", lg: "132px" }}
            >
              <Box
                bgImage={"url(/images/pattern.png)"}
                bgRepeat="no-repeat"
                w={{ base: "249.94px", md: "238.41px", lg: "397px" }}
                h={{ base: "325.17px", md: "309.86px", lg: "425px" }}
                position="absolute"
                transform={{
                  base: "translate(-18%, 0%)",
                  md: "translate(-25%, 0%)",
                  lg: "translate(-16%, 0%)",
                }}
                zIndex="-1"
              />
              <Image
                width={{ base: "auto", md: "289.44px", lg: "397px" }}
                height={{ base: "415px", md: "434.53px", lg: "596px" }}
                src="/images/cta-featured-photo-1.png"
                alt="Cta featured photo"
                borderRadius="lg"
              />
            </Center>
          </Box>
          <Center flex="1" color="#0E101A" justifyContent="flex-start">
            <Box>
              <VStack
                px={{ base: "24px", md: "0px" }}
                spacing="0px"
                alignItems="left"
                textAlign={{ base: "center", md: "unset" }}
              >
                <Heading
                  display={useBreakpointValue({
                    base: "block",
                    md: "none",
                    lg: "block",
                  })}
                  px={{ base: "24px", lg: "0px" }}
                  variant={useBreakpointValue({ base: "h4", lg: "h3" })}
                  maxW="526px"
                  mt={{ base: "40px", lg: "0px" }}
                  mb="24px"
                >
                  {homeContent?.Section3Headline}
                </Heading>
                <Box>
                  <Text
                    textAlign={{ base: "center", md: "unset" }}
                    mx={{ base: "auto", md: "unset" }}
                    variant={useBreakpointValue({
                      base: "paragraph-3",
                      lg: "paragraph-1",
                    })}
                    maxW={{ base: "322px", lg: "578px" }}
                    fontWeight="500"
                    mb="24px"
                  >
                    {homeContent?.Section3Body &&
                      homeContent.Section3Body.map((bodyData, index) => {
                        if (
                          homeContent?.Section3Body &&
                          index === homeContent?.Section3Body.length - 1
                        ) {
                          return (
                            <chakra.span key={bodyData?.id}>
                              {bodyData?.Content}
                            </chakra.span>
                          );
                        }

                        return (
                          <chakra.span key={bodyData?.id}>
                            {bodyData?.Content}
                            <br />
                            <br />
                          </chakra.span>
                        );
                      })}
                  </Text>
                </Box>
                <Box w="full">
                  <GettingStartedLink
                    href={configs.gettingStartedUrl}
                    size={btnSize}
                    w={{ base: "100%", md: "unset" }}
                    mb={{ base: "32px", md: "16px", lg: "24px" }}
                  />
                </Box>
                <VStack spacing="16px" alignItems="flex-start" textAlign="left">
                  {homeContent?.Section3Highlights &&
                    homeContent.Section3Highlights.map((highlight) => {
                      return (
                        <HStack
                          h="full"
                          key={highlight?.id}
                          spacing={{ base: "16px", lg: "14px" }}
                          alignItems="flex-start"
                        >
                          <Box>
                            <Icon w={4} h={4} as={CurlyCheckIcon} />
                          </Box>
                          <Text variant="paragraph-3">
                            {highlight?.Content}
                          </Text>
                        </HStack>
                      );
                    })}
                </VStack>
              </VStack>
            </Box>
          </Center>
        </Flex>
      </Container>
    </chakra.section>
  );
};

export default Cta;
