import React, { Children } from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface CarouselProps {
  autoplaySpeed?: number;
}

const Carousel: React.FC<CarouselProps> = ({
  autoplaySpeed = 3000,
  children,
}) => {
  const swiperSettings = {
    className: "mySwiper",
    slidesPerView: 1,
    // spaceBetween: 72,
    autoHeight: false,
    centeredSlides: true,
    // freeMode: true,
    autoplay: {
      delay: autoplaySpeed,
      disableOnInteraction: false,
    },
    modules: [Autoplay, Pagination],
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    // navigation: false,
    // breakpoints: {
    //   // when window width is >= 320px
    //   320: {
    //     slidesPerView: 1,
    //     spaceBetween: 0,
    //     centeredSlides: true,
    //   },
    //   // when window width is >= 640px
    //   768: {
    //     slidesPerView: noOfSlides > 2 ? 3 : noOfSlides,
    //     spaceBetween: 32,
    //     centeredSlides: true,
    //   },
    //   1440: {
    //     slidesPerView: noOfSlides > 2 ? 3 : noOfSlides,
    //     spaceBetween: 72,
    //   },
    // },
  };

  return (
    <Swiper {...swiperSettings}>
      {Children.toArray(children).map((child, index) => {
        return <SwiperSlide key={"child - " + index}>{child}</SwiperSlide>;
      })}
    </Swiper>
  );
};

export default Carousel;
