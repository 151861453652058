import {
  Center,
  chakra,
  Flex,
  Heading,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import GettingStartedLink from "@components/GettingStartedLink";
import { WithAppConfigs } from "@utils/appConfigs";
import Link from "next/link";
import React from "react";
import { HiChevronRight } from "react-icons/hi";

const Cta1 = ({ configs }: WithAppConfigs) => {
  const btnSize = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });

  return (
    <chakra.section>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent={"space-between"}
        py={{ base: "48px", lg: "56px" }}
        textAlign="center"
      >
        <Heading
          variant={useBreakpointValue({ base: "h4", lg: "h3" })}
          maxW={{ base: "308px", md: "304px", lg: "385px" }}
          mb="16px"
        >
          Hire a Deputy Today
        </Heading>
        <Text
          variant="paragraph-1"
          maxW={{ base: "322px", md: "479px" }}
          mb="32px"
        >
          Arrange a free consultation with GrowthDeputy to see how we can help
          your business grow.
        </Text>
        <Flex
          px={{ base: "24px", md: "0px" }}
          w={{ base: "full", md: "unset" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <GettingStartedLink
            href={configs.gettingStartedUrl}
            size={btnSize}
            mr={{ base: "auto", md: "32px" }}
            ml={{ base: "auto", md: "0px" }}
            mb={{ base: "24px", md: "0px" }}
            w={{ base: "fit-content", md: "unset" }}
            px={{ base: 4 }}
          />

          <Link passHref href="/about">
            <Center _hover={{ cursor: "pointer" }}>
              <Text variant="learn-more-cta" _hover={{ cursor: "pointer" }}>
                Learn More
              </Text>

              <Icon
                as={HiChevronRight}
                height="20px"
                width="20px"
                ml="12px"
                color="primary.darkgreen"
              />
            </Center>
          </Link>
        </Flex>
      </Flex>
    </chakra.section>
  );
};

export default Cta1;
